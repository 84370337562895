import React from "react";

import { graphql } from "gatsby";
import { Layout, Block } from '../../components';

const IndustrialMachinery = ({ data }) => {
	let pageTitle = "Máquinas Industriais";

	let comps = [];
	if (data && data.hasOwnProperty('cms') && data.cms.hasOwnProperty('staticPage')) {
		// console.log(data.cms);
		let page = data.cms.staticPage;

		pageTitle = page.title;

		// iterate blocks and build list of components
		for (let i = 0, l = page.blocks.length; i < l; i++) {
			let block = page.blocks[i];
			comps.push(<Block
				block={block}
				addRow={true}
				key={`dyna_block_${i}`}
				componentKey={`dyna_block_${i}`}
			/>);
		}
	}

	return (
		<Layout pageTitle={pageTitle}>

			<main data-name="layout" className="page-services container-fluid">
				<div className="main-background row pt-5">
					<div className="col-12">
						{comps}
					</div>
				</div>
			</main>

		</Layout>
	);
};

export const query = graphql`
	{
		cms {
			staticMenu(slug: "header") {
				id
				title
				slug
				code
				updatedAt
				items {
					title
					type
					url
					relativeUrl
					items {
						title
						type
						url
						relativeUrl
					}
				}
			}
		}
		cms {
			staticPage(slug: "solucoes-de-negocio-maquinas-industriais.htm") {
				id
				title
				slug
				url
				layout
				isHidden
				navigationHidden
				updatedAt
				blocks {
					id
					blockType
					label
					layoutClasses
					...on CMS_Button {
						text
						icon {
							extension
							mimeType
							canBeInlined
							content(fullUrl: true, isInline: true)
						}
						iconPosition
						url
					}
					...on CMS_CardCarrousel {
						cards {
							title
							subtitle
							media {
								blockType
								label
								title
								description
								mediaType
								mediaContent(fullUrl: true)
							}
							text
							button {
								blockType
								label
								text
								icon {
									extension
									mimeType
									canBeInlined
									content(fullUrl: true, isInline: false)
								}
								iconPosition
								url
							}
						}
					}
					...on CMS_Form {
						title
						fields {
							label
							type
							options {
								label
								value
							}
							size
							isRequired
							validation
							validationRegex
							validationCustom
						}
					}
					...on CMS_Listing {
						title
						items {
							title
							text
							hasCustomIcon
							hasUrl
							url
							icon {
								extension
								mimeType
								canBeInlined
								content(fullUrl: true, isInline: false)
							}
						}
					}
					...on CMS_Media {
						title
						description
						mediaType
						mediaContent(fullUrl: true)
					}
					...on CMS_Slider {
						slides {
							title
							subtitle
							media {
								blockType
								label
								title
								description
								mediaType
								mediaContent(fullUrl: true)
							}
							button {
								blockType
								label
								text
								icon {
									extension
									mimeType
									canBeInlined
									content(fullUrl: true, isInline: false)
								}
								iconPosition
								url
							}
						}
					}
					...on CMS_TextBox {
						textType
						textContent
					}
					... on CMS_BlockGroup {
						id
						blocks {
							id
							blockType
							label
							layoutClasses
							... on CMS_Button {
								text
								icon {
									extension
									mimeType
									canBeInlined
									content(fullUrl: true, isInline: true)
								}
								iconPosition
								url
							}
							... on CMS_CardCarrousel {
								cards {
									title
									subtitle
									media {
										blockType
										label
										title
										description
										mediaType
										mediaContent(fullUrl: true)
									}
									text
									button {
										blockType
										label
										text
										icon {
											extension
											mimeType
											canBeInlined
											content(fullUrl: true, isInline: false)
										}
										iconPosition
										url
									}
								}
							}
							... on CMS_Form {
								title
								fields {
									label
									type
									options {
										label
										value
									}
									size
									isRequired
									validation
									validationRegex
									validationCustom
								}
							}
							...on CMS_Listing {
								title
								items {
									title
									text
									hasCustomIcon
									hasUrl
									url
									icon {
										extension
										mimeType
										canBeInlined
										content(fullUrl: true, isInline: false)
									}
								}
							}
							... on CMS_Media {
								title
								description
								mediaType
								mediaContent(fullUrl: true)
							}
							... on CMS_Slider {
								slides {
									title
									subtitle
									media {
										blockType
										label
										title
										description
										mediaType
										mediaContent(fullUrl: true)
									}
									button {
										blockType
										label
										text
										icon {
											extension
											mimeType
											canBeInlined
											content(fullUrl: true, isInline: false)
										}
										iconPosition
										url
									}
								}
							}
							... on CMS_TextBox {
								textType
								textContent
							}
						}
					}
				}
			}
		}
	}
`;

export default IndustrialMachinery;
